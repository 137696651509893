import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { LoginResponseModel } from './auth/model/auth.model';
import { AuthService } from './auth/services/auth.service';
import { CompanyModel } from './company/company.model';
import { AssetTreeModel } from './home/assets/models/assets.model';
import { CategoryModel } from './shared/components/category/category.model';
import { ChecklistModel, StepModel } from './shared/components/checklist/checklist.model';
import { SelectedLocationModel } from './shared/components/location/model/location.model';
import { SelectedWarehouseModel, WarehouseModel } from './shared/components/warehouse/model/warehouse.model';
import { ApiUrls, BillingStatus } from './util/common.enum';
import { BillingDetails, CurrencyModel, DataResponseModel, MapBoxFeatureModel, MapBoxModel, MapBoxResponseModel, TableDropDownFilterModel, UserModel } from './util/common.model';

@Injectable()
export class AppService {

  private userInfo: UserModel;
  private userId: string;
  private permissions: any;
  private currentRole: string;
  private logoName: string;
  private selectedLocation: SelectedLocationModel;
  private selectedWarehouse: SelectedWarehouseModel;
  private userList: TableDropDownFilterModel[] = [];
  private warehouseList: WarehouseModel[] = [];

  private treeAssets: { [locationId: string]: AssetTreeModel[] } = {};
  private assetTreeData$: BehaviorSubject<AssetTreeModel[]> = new BehaviorSubject<AssetTreeModel[]>([]);
  private userDetails$: BehaviorSubject<{
    userName: string,
    companyName: string,
    name: string
  }> = new BehaviorSubject<{
    userName: string,
    companyName: string,
    name: string
  }>({
    userName: '',
    companyName: '',
    name: ''
  });
  private locationChange$: Subject<SelectedLocationModel> = new Subject<SelectedLocationModel>();
  private warehouseChange$: Subject<SelectedWarehouseModel> = new Subject<SelectedWarehouseModel>();
  private warehouses$: Subject<WarehouseModel[]> = new Subject<WarehouseModel[]>();

  private pageRefresh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private billingDetailsChange$: BehaviorSubject<BillingDetails> = new BehaviorSubject<BillingDetails>({
    billingStatus: '',
    createdAt: ''
  });

  billingPermission = { view: false, edit: false };
  billingStatus = '';
  private categories$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private checklists$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  billingStatus$: BehaviorSubject<any> = new BehaviorSubject<any>({
    billingStatus: '',
    currentBillingPlan: ''
  });

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService,
    private router: Router,
  ) { }

  setUserInfo(userInfo: UserModel) {
    this.userInfo = userInfo;
  }
  getUserInfo() {
    return this.userInfo;
  }
  getBillingDetails(): Observable<BillingDetails> {
    return this.billingDetailsChange$.asObservable();
  }
  setBillingDetails(data: BillingDetails) {
    return this.billingDetailsChange$.next(data);
  }
  setBillingStatusDetails(data: any) {
    return this.billingStatus$.next(data);
  }
  getBillingStatusDetails(): Observable<BillingDetails> {
    return this.billingStatus$.asObservable();
  }
  setUserPermissions() {
    const company = this.getSelectedCompanyId(this.userInfo);
    this.userId = this.userInfo.userId;
    this.currentRole = company.role;
    this.permissions = company.permissions;
    this.logoName = company.logo ? company.logo : '';
    this.billingStatus = company.billingStatus;
    this.setBillingDetails({
      billingStatus: company.billingStatus,
      createdAt: company.createdAt
    });
    if (this.currentRole === 'admin' || this.currentRole === 'creator') {
      this.billingPermission = { view: true, edit: true };
    } else {
      if (company.billingPermission) {
        switch (company.billingPermission) {
          case 'noaccess':
            this.billingPermission = { view: false, edit: false };
            break;
          case 'view':
            this.billingPermission = { view: true, edit: false };
            break;
          case 'edit':
            this.billingPermission = { view: true, edit: true };
            break;
        }
      }

    }
    this.userDetails$.next({ companyName: company.companyName, userName: this.userInfo.userId, name: this.userInfo.name });
    this.pageRefresh$.next(true);
  }

  setLogo(fileName: string) {
    this.logoName = fileName;
  }

  getSelectedCompanyId(userInfo: UserModel): CompanyModel {
    const userCompany = userInfo.companies.find((company: CompanyModel) => company.companyId === localStorage.getItem('companyId')) as CompanyModel;
    if (userInfo.companies.length === 1) {
      localStorage.setItem('companyId', userInfo.companies[0].companyId);
      return userInfo.companies[0];
    }
    return userCompany;
  }

  setUserList(userList: TableDropDownFilterModel[]) {
    this.userList = userList;
  }

  getUserList() {
    return this.userList;
  }

  getUserDetails() {
    return this.userDetails$.asObservable();
  }

  getCompanyLogo() {
    return this.logoName;
  }

  getUserAccess(locationId = '', feature = '') {
    if (this.currentRole === 'admin' || this.currentRole === 'creator') {
      return { hasReadAccess: true, hasWriteAccess: this.isReadOnly() ? false : true };
    } else if (locationId && this.permissions && feature) {
      if (this.permissions[locationId][feature] === 'edit') {
        return { hasReadAccess: true, hasWriteAccess: this.isReadOnly() ? false : true };
      } else if (this.permissions[locationId][feature] === 'view') {
        return { hasReadAccess: true, hasWriteAccess: false };
      } else {
        return { hasReadAccess: false, hasWriteAccess: false };
      }
    } else if (this.permissions) {
      return { hasReadAccess: false, hasWriteAccess: false };
    } else {
      return { hasReadAccess: false, hasWriteAccess: false };
    }
  }
  getUserAlteastOneLocationAccess() {
    if (this.currentRole === 'admin' || this.currentRole === 'creator') {
      return { read: true, write: this.isReadOnly() ? false : true };
    } else if (this.permissions) {
      return {
        read: Object.keys(this.permissions).some(k => this.permissions[k]?.assets !== 'noaccess'),
        write: this.isReadOnly() ? false : Object.keys(this.permissions).some(k => this.permissions[k]?.assets === 'edit')
      };
    }
    return { read: false, write: false };
  }
  getUserAccessLocations() {
    if (this.permissions && Object.keys(this.permissions) && Object.keys(this.permissions).length) {
      const locations: string[] = []
      Object.keys(this.permissions).forEach(k => {
        if (this.permissions[k]?.assets !== 'noaccess' || this.permissions[k]?.workOrder !== 'noaccess') {
          locations.push(k);
        }
      });
      return locations;
    }
    return [];
  }
  getUserBillingAccess() {
    return this.billingPermission;
  }
  isAdmin() {
    return (this.currentRole === 'admin' || this.currentRole === 'creator');
  }
  isSettingUsersReadOnly() {
    return !['' + BillingStatus.Active, BillingStatus.TrailMode].includes(this.billingStatus);
  }
  isReadOnly() {
    return !['' + BillingStatus.Active, BillingStatus.PendingInvoice, BillingStatus.TrailMode].includes(this.billingStatus);
    // return this.billingStatus === BillingStatus.TrailEnd || this.billingStatus === BillingStatus.AutoPause || this.billingStatus === BillingStatus.ManualPause;
  }

  checkStatus(): Observable<LoginResponseModel> {
    return this.httpClient.post<LoginResponseModel>(`${environment.baseUrl}${ApiUrls.CheckStatus}`, {})
      .pipe(
        tap((data) => {
          if (data.success) {
            this.auth.setFbToken(data.fbToken);
            this.setUserInfo(data.user);
            this.setCompanyData(data);
            localStorage.setItem('AUTH', data.token);
            this.auth.authenticateFb();
          } else if (!data.auth) {
            this.logout();
          }
        }, (err) => {
          if (err.message === 'Unauthorized' || err.logout === true) {
            this.logout();
          }
        })
      );
  }

  logout() {
    this.auth.fbLogout();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  isRefreshed(): Observable<boolean> {
    return this.pageRefresh$.asObservable();
  }

  getPlaceNames(searchKey: string): Observable<MapBoxModel[]> {
    return this.httpClient.get<MapBoxResponseModel>(`${ApiUrls.MapBox}${searchKey}.json?types=place&autocomplete=true&access_token=${environment.mapBoxAccessKey}`).pipe(map((res) => this.mapPlaceNames(res)));
  }

  mapPlaceNames(response: MapBoxResponseModel): MapBoxModel[] {
    if (response && response.features) {
      return response.features.map((value: MapBoxFeatureModel) => {
        return { placeName: value.place_name, coordinates: value.geometry.coordinates }
      })
    }
    return [];
  }

  getCityNames(searchKey: string): Observable<MapBoxFeatureModel[]> {
    return this.httpClient.get<MapBoxResponseModel>(`${ApiUrls.MapBox}${searchKey}.json?types=place&autocomplete=true&access_token=${environment.mapBoxAccessKey}`).pipe(map((res) => this.mapCityNames(res)));
  }

  mapCityNames(response: MapBoxResponseModel): MapBoxFeatureModel[] {
    if (response && response.features) {
      return response.features;
    }
    return [];
  }

  getSelectedLocation() {
    return this.selectedLocation;
  }

  setLocation(selectedLocaiton: SelectedLocationModel) {
    this.selectedLocation = selectedLocaiton;
    this.loadAssets();
    this.locationChange$.next(selectedLocaiton);
  }

  getLocationChange() {
    return this.locationChange$.asObservable();
  }

  setSelectedWarehouse(warehouse: SelectedWarehouseModel) {
    this.selectedWarehouse = warehouse;
  }

  setWarehouse(warehouse: SelectedWarehouseModel) {
    if (!this.selectedWarehouse || this.selectedWarehouse.warehouseId !== warehouse.warehouseId) {
      this.selectedWarehouse = warehouse;
      this.warehouseChange$.next(warehouse);
    }
  }

  getWarehouseChange() {
    return this.warehouseChange$.asObservable();
  }

  getSelectedWarehouse() {
    return this.selectedWarehouse;
  }

  getWarehouses() {
    return this.warehouses$.asObservable();
  }

  getWarehouseList() {
    return this.warehouseList;
  }

  fetchWarehouses(source: string) {
    return this.httpClient.get<DataResponseModel<WarehouseModel[]>>(`${environment.baseUrl}${ApiUrls.GetWarehouses}?${source ? 'source=' + source : ''}`)
      .pipe(map((data => data.data)))
      .subscribe((data: any) => {
        if (data) {
          this.setWarehouses(data);
        }
      });
  }

  setWarehouses(warehouses: WarehouseModel[]) {
    this.warehouseList = warehouses;
    this.warehouses$.next(warehouses);
  }

  loadAssets() {
    const locationId = this.selectedLocation.locationId;
    this.httpClient.get<DataResponseModel<AssetTreeModel[]>>(`${environment.baseUrl}${ApiUrls.TreeAsset}?locationId=${locationId}`)
      .pipe(
        map((data) => data.data)
      ).subscribe((data: any) => {
        //this.assetData$.next(data);
        if (data) {
          this.treeAssets[locationId] = [...data];
          this.assetTreeData$.next(data);
        }
      });
  }

  loadUsers(): Observable<TableDropDownFilterModel[]> {
    return this.httpClient.get<DataResponseModel<TableDropDownFilterModel[]>>(`${environment.baseUrl}${ApiUrls.GetUsers}`).pipe(
      map((data) => data.data.map((value: any) => ({ text: `${value.name} (${value.userId})`, value: value.userId })))
    );
  }

  getAssetTreeData() {
    return this.assetTreeData$.asObservable();
  }

  getAssets(locationId: string): AssetTreeModel[] | Observable<DataResponseModel<any>> {
    if (this.treeAssets[locationId]) {
      return this.treeAssets[locationId];
    }
    const resp = this.httpClient.get<DataResponseModel<AssetTreeModel[]>>(`${environment.baseUrl}${ApiUrls.TreeAsset}?locationId=${locationId}`);
    resp
      .pipe(
        map((data) => data.data)
      ).subscribe((data: any) => {
        if (data) {
          this.treeAssets[locationId] = [...data];
          this.assetTreeData$.next(data);
        }
      });
    return resp;
  }

  setCompanyData(data: LoginResponseModel) {
    if (data.user?.companies && data.user.companies.length) {
      if (data.user.companies.length > 1) {
        this.setUserPermissions();
      } else {
        data.user.companies.forEach((company: CompanyModel) => {
          this.setUserPermissions();
        });
      }
    }
  }

  updatUserDetails(formData: any) {
    return this.httpClient.post<any>(`${environment.baseUrl}${ApiUrls.UpdateUserDetails}`, formData)
  }

  getUserId() {
    return this.userId;
  }
  addAssetCustomField(data: any): Observable<any> {
    const url = data.fieldId ? `${environment.baseUrl}${ApiUrls.updateAssetCustomField}` : `${environment.baseUrl}${ApiUrls.addAssetCustomField}`;
    return this.httpClient.post<any>(`${url}`, data);
  }
  getCustomFieldList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl}${ApiUrls.getAssetCustomFields}`);
  }
  getCurrency(): Observable<CurrencyModel[]> {
    return this.httpClient.get<DataResponseModel<CurrencyModel[]>>(`${environment.baseUrl}${ApiUrls.getCurrency}`).pipe(map((data) => data.data));
  }

  getUserRole() {
    return this.currentRole;
  }

  sendSelectedCompany(companyId: string): Observable<any> {
    return this.httpClient.post<string>(`${environment.baseUrl}${ApiUrls.SelectCompany}`, { companyId }).pipe(map((res) => res));
  }

  getCategories(id: string): Observable<CategoryModel[]> {
    const params = new HttpParams()
      .set('parentLocation', id);
    return this.httpClient.get<DataResponseModel<CategoryModel[]>>(`${environment.baseUrl}${ApiUrls.GetCategories}`, { params }).pipe(map((data) => data.data));
  }
  saveCategory(category: CategoryModel): Observable<any> {
    const url = category.id ? ApiUrls.UpdateCategory : ApiUrls.AddCategory;
    return this.httpClient.post<any>(`${environment.baseUrl}${url}`, category);
  }
  refreshCategories(number: number) {
    this.categories$.next(number);
  }
  getCategoriesChange() {
    return this.categories$.asObservable();
  }

  getChecklists(type = ''): Observable<ChecklistModel[]> {
    const params = new HttpParams();
    return this.httpClient.get<DataResponseModel<ChecklistModel[]>>(`${environment.baseUrl}${ApiUrls.GetChecklists}?${type ? 'type=' + type : ''}`, { params }).pipe(map((data) => data.data));
  }
  saveChecklist(checklist: ChecklistModel): Observable<any> {
    const url = checklist.id ? ApiUrls.UpdateChecklist : ApiUrls.AddChecklist;
    return this.httpClient.post<any>(`${environment.baseUrl}${url}`, checklist);
  }
  refreshChecklists(number: number) {
    this.checklists$.next(number);
  }
  getChecklistsChange() {
    return this.checklists$.asObservable();
  }
  saveChecklistItem(data: any): Observable<any> {
    const url = data.id ? ApiUrls.UpdateChecklistItem : ApiUrls.AddChecklistItem;
    return this.httpClient.post<any>(`${environment.baseUrl}${url}`, data);
  }

  getBillingStatus() {
    return this.httpClient.get<any>(`${environment.baseUrl}${ApiUrls.getStatus}`);
  }
}

